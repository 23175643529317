import {Component, Input, OnInit} from '@angular/core';
import {SlideInPanelService} from './slide-in-panel.service';

@Component({
  selector: 'app-slide-in-panel',
  templateUrl: './slide-in-panel.component.html',
  styleUrls: ['./slide-in-panel.component.scss']
})
export class SlideInPanelComponent implements OnInit {
  @Input() isVisible = false;
  @Input() title: string;

  constructor(public slideInPanelService: SlideInPanelService) { }

  ngOnInit() {
  }

}
