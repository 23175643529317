<div class="cd-panel cd-panel--from-right js-cd-panel-main" [ngClass]="{'cd-panel--is-visible': slideInPanelService.isVisible}">
  <header class="cd-panel__header" [ngClass]="{'cd-panel--is-visible': slideInPanelService.isVisible}">
    <h3>{{title}}</h3>
    <a (click)="slideInPanelService.isVisible = false;" class="cd-panel__close js-cd-close">Close</a>
  </header>
  <div class="cd-panel__container" [ngClass]="{'cd-panel--is-visible': slideInPanelService.isVisible}">
    <div class="cd-panel__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
