import { Component } from '@angular/core';
import {SlideInPanelService} from './slide-in-panel/slide-in-panel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'maybyte';
  constructor(public slideInPanelService: SlideInPanelService) { }

  year: number = new Date().getFullYear()
}

